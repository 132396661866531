import { MapItem } from '@capturum/ui/api';

export class ScanOrderConfirmShipment {
  public static readonly type = '[Confirm Shipment] Scanned shipment order';

  constructor(public shipment: MapItem) {}
}

export class ScanBoxConfirmShipment {
  public static readonly type = '[Confirm Shipment] Scanned shipment package';

  constructor(public currentBox: MapItem) {}
}

export class ResetBoxConfirmShipment {
  public static readonly type = '[Confirm Shipment] Reset confirm shipment package';
}

export class AddBoxConfirmShipment {
  public static readonly type = '[Confirm Shipment] Add shipment';

  constructor(public box: MapItem) {}
}

export class ResetConfirmShipment {
  public static readonly type = '[Confirm Shipment] Reset Start Shipment';
}
